export { matchers } from "/.svelte-kit/generated/client/matchers.js";

export const nodes = [
	() => import("/.svelte-kit/generated/client/nodes/0.js"),
	() => import("/.svelte-kit/generated/client/nodes/1.js"),
	() => import("/.svelte-kit/generated/client/nodes/2.js"),
	() => import("/.svelte-kit/generated/client/nodes/3.js"),
	() => import("/.svelte-kit/generated/client/nodes/4.js"),
	() => import("/.svelte-kit/generated/client/nodes/5.js"),
	() => import("/.svelte-kit/generated/client/nodes/6.js"),
	() => import("/.svelte-kit/generated/client/nodes/7.js"),
	() => import("/.svelte-kit/generated/client/nodes/8.js"),
	() => import("/.svelte-kit/generated/client/nodes/9.js"),
	() => import("/.svelte-kit/generated/client/nodes/10.js"),
	() => import("/.svelte-kit/generated/client/nodes/11.js"),
	() => import("/.svelte-kit/generated/client/nodes/12.js"),
	() => import("/.svelte-kit/generated/client/nodes/13.js"),
	() => import("/.svelte-kit/generated/client/nodes/14.js"),
	() => import("/.svelte-kit/generated/client/nodes/15.js"),
	() => import("/.svelte-kit/generated/client/nodes/16.js"),
	() => import("/.svelte-kit/generated/client/nodes/17.js"),
	() => import("/.svelte-kit/generated/client/nodes/18.js"),
	() => import("/.svelte-kit/generated/client/nodes/19.js"),
	() => import("/.svelte-kit/generated/client/nodes/20.js"),
	() => import("/.svelte-kit/generated/client/nodes/21.js"),
	() => import("/.svelte-kit/generated/client/nodes/22.js"),
	() => import("/.svelte-kit/generated/client/nodes/23.js"),
	() => import("/.svelte-kit/generated/client/nodes/24.js"),
	() => import("/.svelte-kit/generated/client/nodes/25.js"),
	() => import("/.svelte-kit/generated/client/nodes/26.js")
];

export const server_loads = [0,3,4];

export const dictionary = {
		"/": [5],
		"/adaptiveCard": [6],
		"/base/about": [~7],
		"/base/contact": [8],
		"/base/privacy": [9],
		"/base/terms": [10],
		"/docs": [11,[2]],
		"/docs/adaptiveCard": [12,[2]],
		"/docs/api": [13,[2]],
		"/domains/getSSL": [~14,[3]],
		"/domains/getSSL/edit/[id]": [~15,[3]],
		"/domains/getSSL/success": [16,[3]],
		"/redirects": [~17,[4]],
		"/redirects/edit/[id]": [~18,[4]],
		"/tech": [19],
		"/user/getApiKey": [~20],
		"/user/home": [21],
		"/user/profile": [22],
		"/user/register": [~23],
		"/user/signUpSuccess": [24],
		"/user/unsubscribe": [25],
		"/user/verifyEmail/emailVerified": [26]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from "/.svelte-kit/generated/root.svelte";